<template>
  <!-- <v-container fluid>
    <v-row cols="12" md="12">
      <v-col md="4" style="background: gray;">1</v-col>
      <v-col md="4" style="background: red;">2</v-col>
      <v-col md="4" style="background: rebeccapurple;">3</v-col>
    </v-row>
  </v-container> -->
  <!-- <section class="d-flex" cols="12" md="12">
    <div style="background: green;">
      <h1 class="font-weight:bold">Horario</h1>
      <p><strong>L-V</strong> 9:30 - 14:00/17:00 - 20:30</p>
      <p><strong>Sábado</strong> 9:30 - 14:00/17:00 - 20:30</p>
      <p><strong>Domingo</strong> Cerrado</p>
    </div>

    <div style="background: red;">
      <h1>Tienda Torrevieja</h1>
      <h3>Santiago de Compostela</h3>
      <p>
        Rúa de García Prieto, 64 (Bajo). Santiago de Compostela A Coruña.
      </p>
      <p>elmagomerlincerrajeria@gmail.com</p>
    </div>

    <div>
      <h1>Orihuela Costa</h1>
      <h3>Santiago de Compostela</h3>
      <p>Calle Surinan 4 - 2, Orihuela Costa. Alicante.</p>
      <p>elmagomerlincerrajeria@gmail.com</p>
    </div>
  </section> -->
</template>

<script>
export default {
  name: "BaseBusinessContact",

  props: {
    dark: Boolean,
    dense: Boolean,
    title: String
  },

  data: () => ({
    // business: [
    //   {
    //     icon: "mdi-map-marker-outline",
    //     title: "Address",
    //     text:
    //       "Santiago de Compostela: Rúa de García Prieto, 64 (Bajo). Santiago de Compostela A Coruña.  "
    //   },
    //   {
    //     icon: "mdi-map-marker-outline",
    //     title: "Address",
    //     text: "Orihuela Costa: Calle Surinan 4 - 2, Orihuela Costa. Alicante."
    //   },
    //   {
    //     icon: "mdi-cellphone",
    //     title: "Phone",
    //     text: "669783741"
    //   },
    //   {
    //     icon: "mdi-email",
    //     title: "Email",
    //     text: "elmagomerlincerrajeria@gmail.com"
    //   }
    // ]
  })
};
</script>
