// Imports
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: "/",
      component: () => import("@/layouts/home/Index.vue"),
      children: [
        {
          path: "",
          name: "Inicio",
          component: () => import("@/views/home/Index.vue")
        },
        {
          path: "cerrajeria",
          name: "Cerrajería",
          component: () => import("@/views/locksmith/Index.vue"),
          meta: { src: require("@/assets/herolocksmith.jpg") }
        },
        {
          path: "fontaneria",
          name: "Fontanería",
          component: () => import("@/views/plumbing/Index.vue"),
          meta: { src: require("@/assets/heroplumbing.jpg") }
        },
        {
          path: "electricidad",
          name: "Electricistas",
          component: () => import("@/views/electricity/Index.vue"),
          meta: { src: require("@/assets/heroelectricity.jpg") }
        },
        {
          path: "persianas",
          name: "Persianas",
          component: () => import("@/views/persianas/Index.vue"),
          meta: { src: require("@/assets/heroblinds.jpeg") }
        },
        {
          path: "ventanas",
          name: "ventanas",
          component: () => import("@/views/ventanas/Index.vue"),
          meta: { src: require("@/assets/heroblinds.jpeg") }
        },
        {
          path: "albanileria",
          name: "Albañilería",
          component: () => import("@/views/albanileria/Index.vue"),
          meta: { src: require("@/assets/herobuilder.jpg") }
        },
        {
          path: "climatizacion",
          name: "Climatización",
          component: () => import("@/views/climatizacion/Index.vue"),
          meta: { src: require("@/assets/heroappliance.jpg") }
        },
        {
          path: "contacto",
          name: "Contacto",
          component: () => import("@/views/us/Index.vue"),
          meta: { src: require("@/assets/herous.jpg") }
        },
        {
          path: "*",
          name: "FourOhFour",
          component: () => import("@/views/404/Index.vue")
        }
      ]
    }
  ]
});

export default router;
